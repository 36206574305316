<template>
  <v-card>
    <v-card-title>Usuários do sistema</v-card-title>

    <v-data-table
      :headers="headers"
      :items="users"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click="$router.push('/usuarios/cadastrar')"
            >Cadastrar +</v-btn
          >
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Editar perfil"
          @click="editUser(item.id)"
          small
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Deletar perfil"
          @click="deleteUser(item.id)"
          small
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>



<script>
import errorHandler from "@/helpers/error_handler";
export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Nome", value: "fullName" },
      { text: "CPF", value: "taxDocument" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    users: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async loadUsers() {
      try {
        this.loading = true;

        let url = `/users`;

        const response = await this.$axios.get(url);

        this.setUsers(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setUsers(data) {
      const cpData = [...data];
      this.users = cpData.map((u) => ({
        fullName: `${u.firstName} ${u.lastName}`,
        ...u,
      }));
    },
    async deleteUser(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar o usuário com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/users/${id}`;

        await this.$axios.delete(url);

        this.deleteUserFromTable(id);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteUserFromTable(id) {
      const index = this.users.findIndex((p) => p.id === id);

      this.users.splice(index, 1);
    },
    editUser(id) {
      this.$router.push(`/usuarios/${id}`);
    },
  },
  computed: {},
  created() {
    this.loadUsers();
  },
};
</script>

<style>
</style>